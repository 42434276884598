import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Events from '../components/events/events'
import TopSocial from '../components/top-social'
// import Instagram from '../components/instagram/grams'

class EventsPage extends React.Component {
  render() {
    const events = this.props.data.contentfulEventsPage
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <Helmet title={events.title} />
        <TopSocial
          heroImage={events.heroImage.gatsbyImageData}
          heroTitle={events.heroTitle}
        />
        <Events sectionCount={sectionCount} />
        {/* <Instagram sectionCount={(sectionCount += 1)} /> */}
      </Layout>
    )
  }
}

export default EventsPage

export const pageQuery = graphql`
  query EventsPageQuery {
    contentfulEventsPage(id: { eq: "e484ebd1-93b2-5e52-90c0-784463b4e44b" }) {
      heroTitle
      heroImage {
        gatsbyImageData(width: 1122, layout: CONSTRAINED)
      }
      title
    }
  }
`
